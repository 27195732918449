@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: white;
}

.navigation {
  position: fixed;
  width: 100%;
  background-color: var(--hover-green);
  height:50px;
  align-items: center;
  margin:0;
  padding:0 10px;
  z-index: 1;
}

.navigation li{
  margin:15px 15px 0;
  list-style-type: none;
  float: right;
}

.navigation li:first-child{
  margin:15px 0;
  float: left;
}

.navigation li a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:5px;
  align-items: center;
  color: var(--white);
  width: fit-content;
  height: fit-content;
  font-size: 17px;
  margin: 0;
  text-decoration: none;
  font-family: var(--font-family);
  cursor: pointer;
}

.navigation li a .icon {
  font-size: 15px;
}

.navigation a:hover {
  color: var(--containers-bg-color);
}

.caseStudy{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container{
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
}

.container img:nth-child(2){
  width:100px;
  height: 100px;
  object-fit: contain;
  position: relative;
  top:-50px;
  left:25%;
}

.sectionsNavigation{
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 30px;
  /*position: fixed;*/
}

.subcontainer{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.subcontainer h1{
  margin:0;
  padding: 0;
  font-family: var(--font-family);
  color: var(--green);
  font-size: 40px;
}

.subcontainer p{
  font-family: var(--font-family);
  line-height: var(--p-line-height);
  width:100%;
  text-align: justify;
}

.subcontainer h2{
  margin:50px 0 40px;
  padding: 0;
  font-size: 20px;
  color: var(--green)
}

.sectionsNavigation a{
  text-decoration: none;
  color:var(--dark-green);
  font-size: 14px;
  line-height: 2rem;
  font-family: var(--font-family);
  width: fit-content;
}

.sectionsNavigation a:hover{
  color: var(--hover-green);
}

.copy{
  width: 80%;
  position: relative;
  padding: 0 7.5% 100px;
}

.copy img{
  display: block;
  width: 70%;
  height: 250px;
  object-fit: cover;
  margin: 0 auto;
  box-shadow: 0 0 4px rgb(128, 128, 128);
  border-radius: 5px;
}

.copy iframe{
  display: block;
  margin: 0 auto;
  width: 70%;
  height: 250px;
  border-radius: 5px;
  box-shadow: 0 0 4px rgb(128, 128, 128);
}

.footer{
  width: 100%;
  height: 40px;
  font-size: 10px;
  background-color: var(--containers-bg-color);
  text-align: center;
  font-family: var(--font-family);
  color:var(--dark-green);
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer a{
  font-family: var(--font-family);
  color: var(--green);
  font-weight: var(--font-weight);
}

.footer a:hover{
  color:var(--hover-green)
}

@media screen and (max-width: 768px){
  .subcontainer{
    flex-direction: column;
  }

  .sectionsNavigation{
    display: none;
  }

  .copy{
    width:100%;
    left: 0 !important;
  }

  .container img:nth-child(2){
    left:30px;
  }

  .copy h1{
    font-size: 25px;
  }

  .copy p{
    margin:0;
    width: 85%;
    font-size: 12px;
  }

  .copy img, .copy iframe{
    margin:0;
    width: 85%;
  }

  .footer{
    text-align: center;
    padding:0;
    width: 100%;
  }

  .footer a{
    align-self: center;
  }
}


.playBtn{
  margin: 150px 0 0 0;
  padding: 10px 20px;
  border-radius: 10px;
  font-family: 'roboto';
  background-color: palevioletred;
  color: white;
  font-weight: bold;
  font-size: medium;
}

.playBtn:hover{
  cursor: pointer;
}

.backgroundPicture{
  width: 100%;
  height: 200px;
  object-fit: cover;
  background-color: black;
}