@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  z-index: 1;
  position: fixed;
  padding: 0.4rem;
}

.navbar ul {
  margin: 0;
  padding: 0;
  height: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 5px;
}

.navbar ul li {
  list-style-type: none;
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content:center;
}

.navbar ul li .link,
.navbar ul li button,
.navbar ul li a {
  text-align: center;
  text-decoration: none;

  font-family: var(--font-family);
  font-size: var(--button-font-size);
  font-weight: var(--font-weight);
  line-height: 3.438rem;

  cursor: pointer;
  vertical-align: middle;
  display: flex;
  align-items:center;
  justify-content:center;
  padding: 0 20px;

  border-radius: var(--button-border-radius);
  height: var(--button-height);
}

.navbar ul li .link:hover,
.navbar ul li a:hover {
  color: var(--hover-green);
}

.navbar ul li .active {
  color: var(--white);
  background-color: var(--green);
}

.navbar ul li:last-child {
  display: none;
}

/*RESPONSIVENESS*/
@media screen and (max-width: 601px) {
  .navbar ul{
    justify-content: right;
  }
  .navbar ul li:not(:last-child) {
    display: none;
  }
  .navbar ul li:last-child {
    display: inline-block;
  }

  .responsive {
    background-color: var(--hover-green) !important;
    position: fixed;
    width: 100%;
    z-index: 1;
    padding-top:20px;
  }

  .responsive li{
    list-style-type: none;
  }

  .responsive li .link,
  .responsive li button,
  .responsive li a{
    float: none;
    display: block;
    text-align: left;
    color: var(--white);
    text-decoration: none;
    font-family: var(--font-family);
    /*font-size: 20px;*/
    cursor: pointer;
    padding: 10px 10px 10px;
    width: 100%;
  }

  .responsive ul li .link:hover,
  .responsive ul li a:hover {
    color: #14dda8;
  }
  .responsive ul li button .icon {
    position: absolute;
    right: 0;
    top: 0;
    padding: 18px 25px;
  }

  .responsive ul li .active {
    color: var(--white);
    background-color: var(--green);
  }
}


/*Mobile*/


/*Tablets*/
@media screen and (max-width: 1024px) {}


.hamburger{
  border: none;
  background-color: inherit;
}