@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
.hero_section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 520px;
}

.content{
  height:350px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}


/*LEFT SIDE*/
.left_side{
  display: flex;
  flex-direction: column;
}

h1 {
  color: var(--dark-green);
  font-family: var(--font-family);
  font-size: var(--heading-font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
}
/**/


/*RIGHT SIDE*/
.content .right_side{
  display: flex;
  flex-direction: column;
  gap:20px;
}

.right_side img {
  height: 300px;
  width: 300px;
  border-radius: 10px;
  object-fit: cover;
}

.fa_icon{
  height: 30px !important;
  width: 30px !important;
  color: var(--green);
}

.fa_icon:hover {
  color: #14dda8;
}

.centered_link_container{
  animation: MoveUpDown 1s linear infinite;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.primary_button {
  width: 40px;
  height:40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background-color: var(--green);
  font-size: 25px;
  border-radius: 50%;
  font-family: 'Roboto', sans-serif;
  padding: 5px;
  cursor: pointer;
}

.primary_button:hover {
  background-color: var(--hover-green);
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media screen and (max-width: 600px){
  .hero_section{
    height: fit-content;
    padding: 6rem 0 5rem 0;
  }

  .content{
    height: fit-content;
    width: 100%;
    flex-direction: column-reverse;
    gap:1rem;
  }

  .right_side{
    width: 80%;
    flex-direction: unset !important;
    justify-content: center;
  }

  .right_side img{
    width: 100%;
    height: 80%;
  }

  .left_side{
    width: 80%;
  }

  h1{
    margin-top: 2rem;
    font-size: 1.4rem;
    line-height: 2.5rem;
  }
}

@media screen and (min-width: 601px){
  .hero_section{
    height: fit-content;
    padding: 8rem 0 6rem 0;
  }

  .content{
    height: fit-content;
    width: 100%;
    gap:1rem;
  }

  .right_side{
    width: 35%;
  }

  .right_side img{
    width: 100%;
    height: 100%;
  }

  h1{
    font-size: 2rem;
    line-height: 3rem;
  }
}

@media screen and (min-width: 768px){
  h1{
    font-size: 2.3rem;
    line-height: 4rem;
  }
}

@media screen and (min-width: 1075px) {
  .hero_section{
    display: flex;
    padding: unset !important;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 520px;
  }

  .content{
    height:350px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .left_side{
    display: flex;
    flex-direction: column;
  }

  h1 {
    color: var(--dark-green);
    font-family: var(--font-family);
    font-size: var(--heading-font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
  }

  .content .right_side{
    width: 20% !important;
    display: flex;
    flex-direction: column;
    gap:20px;
  }

  .right_side img {
    height: 300px;
    width: 300px;
    border-radius: 10px;
    object-fit: cover;
  }

  .fa_icon{
    height: 30px !important;
    width: 30px !important;
    color: var(--green);
  }

  .fa_icon:hover {
    color: #14dda8;
  }

  .centered_link_container{
    animation: MoveUpDown 1s linear infinite;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .primary_button {
    width: 40px;
    height:40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--white);
    background-color: var(--green);
    font-size: 25px;
    border-radius: 50%;
    font-family: 'Roboto', sans-serif;
    padding: 5px;
    cursor: pointer;
  }

  .primary_button:hover {
    background-color: var(--hover-green);
  }
}
