#footer {
  position: absolute;
  background-color: var(--green);
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap:50px;
  justify-content: center;
  align-items: center;
}
.socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap:20px;
}

.social_link{
  height: 20px !important;
  width: 20px !important;
  color: white;
}

.social_link:hover {
  color: var(--light-green);
}