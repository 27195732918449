@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  height: 250px;
  width: 600px;
  border-radius: 15px;
  position: relative;
  float: right;
  background-color: var(--green);
  box-shadow: 0 0 10px var(--dark-green);
}

.text{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.previewImage{
  width: 50%;
  object-fit: contain;
}


.card .projName {
  font-size: 25px;
  font-family: 'Bebas Neue', cursive;
  color: white;
  margin: 0;
  letter-spacing: 0.3mm;
}

.technologies {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height:fit-content;
  gap: 5px;
}
.technologies span{
  color: #14dda8;
  background-color: #1b262c;
  padding: 7px;
  font-size: 10px;
  font-weight: bolder;
  font-family: 'Roboto', sans-serif;
  border-radius: 7px;
}

.description {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  margin: 5px 0 0;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 5px;
}

.icon {
  color: white;
  font-size: 25px;
}

.card_link{
  text-decoration: none;
  color: var(--white);
  /*border:2px solid ;*/
  cursor: pointer;
  padding:5px 8px;
  border-radius: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 10px;
  background-color: var(--hover-green);
}


@media screen and (max-width:500px){
  .card{
    width:80% !important;
    height: fit-content;
    flex-direction: column;
  }

  .container{
    display: flex;
    flex-direction: space-between;
    gap:20px;
    width:100%;
  }

  .projName{
    align-self: center;
  }

  .previewImage{
    width:300px;
    align-self: center;
  }

  .text{
    width: 100%;
    gap:20px;
  }

  .technologies{
    width: 100%;
  }

  .technologies span{
    font-size: 10px;
  }

  .description{
    font-size: 12px;
    margin:0;
    width:100%;
  }
}

@media screen and (min-width:501px){
  .card{
    padding: 15px;
    height: 250px;
    width: 80%;
    flex-direction: column;
    justify-content: space-between;
  }

  .container{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width:100%;
    height: 100%;
  }

  .text{
    width: 60%;
    justify-content: space-evenly;
    gap:20px;
  }

  .technologies{
    width: 100%;
  }

  .technologies span{
    font-size: 10px;
  }

  .description{
    font-size: 12px;
    margin:0;
    width:100%;
  }
}

@media screen and (min-width:700px){
  .card{
    padding: 25px 15px 15px 15px;
    height: 250px;
    width: 600px;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .container{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width:100%;
    height: 100%;
  }



  .previewImage{
    width:40%;
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
    border-radius: 15px;
  }

  .text{
    width: 60%;
    justify-content: space-evenly;
  }

  .technologies{
    width: 100%;
  }

  .technologies span{
    font-size: 10px;
  }

  .description{
    font-size: 15px;
    margin:0;
    width:100%;
  }
}