@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
.contact {
  width: 100%;
  margin: 100px 0 220px 0;
}

.contact .subcontainer{
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
}

.contact form {
  width:240px;
  height: inherit;
  background-color: var(--hover-green);
  box-shadow: 0 0 3.5px black;
  padding: 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap:10px;
}

.contact form .field{
  display: flex;
  flex-direction: column;
}

.contact form label {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  float: left;
  color: var(--white);
  font-weight: bold;
}

.contact form input,
#textarea {
  border-radius: 3px;
  width: 230px;
  color: var(--green);
  font-family: 'Roboto', sans-serif;
  font-size: small;
  background-color: var(--containers-bg-color);
  border-color: var(--white);
  box-shadow: 0 0 3px rgb(128, 128, 128);
}

.contact form input{
  height: 20px;
}


#textarea{
  resize: none;
  height: 80px;
}

.contact .submit {
  background-color: var(--green);
  color: var(--white);
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: bold;
  padding: 5px;
  border-radius:3px;
  border: 1px solid var(--green);
  align-items: center;
  cursor: pointer;
  height: fit-content;
  width: 80px !important;
}

.contact .submit:hover {
background-color: #0ea486;
  border: 1px solid #0ea486;

}

.testimonials{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.testimonials h3{
  font-family: var(--font-family);
  color: var(--dark-green);
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin:0;
}

.testimony{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--containers-bg-color);
  box-shadow: 0 0 3px rgb(128, 128, 128);
  padding: 15px;
}

.referral{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.referral span{
  color: var(--dark-green);
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
}

.referral .icon{
  color:var(--dark-green);
  font-size: 10px;
}

.testimony p{
  color: var(--dark-green);
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  margin: 5px 0 0 20px;
  font-weight: bold;
}

.social_link{
  color: var(--dark-green);
  font-size: 15px;
}

.right_side h4{
  color: var(--dark-green);
  font-size: 40px;
  font-family: 'Roboto', sans-serif;
  margin: 40px 0 10px 0;
}

.contact_info{
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin:0 0 10px 0;
}

.contact_info span{
  font-family: 'Roboto', sans-serif;
  color: var(--dark-green);
}

.form .send{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:10px;
}

.form .send .notification{
  font-size: 12px;
  color:var(--white);
  font-family: var(--font-family);
  float: left;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.spinner{
  color:var(--white);
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@media screen and (max-width: 425px) {
  .contact .subcontainer{
    flex-direction: column;
    align-items: center;
  }

  .form{
    justify-content: center;
    width: 90% !important;
  }

  .contact form input,
  #textarea {
    width: 95%;
  }

  .right_side{
    width: 100%;
  }
}

@media screen and (min-width: 426px) {
  .contact .subcontainer{
    flex-direction: column;
    align-items: center;
  }

  .form{
    justify-content: center;
    width: 60% !important;
  }

  .contact form input,
  #textarea {
    width: 95%;
  }

  .right_side{
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .contact .subcontainer{
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap:30px;
  }

  .form{
    justify-content: center;
    width: 240px !important;
  }

  .contact .subcontainer form input,
  #textarea {
    width: 95%;
  }

  .right_side{
    width: 60%;
  }
}