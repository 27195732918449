@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
.aboutme {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 150px;
}
.container{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap:50px;
    padding:50px;
    /*align-items: center;*/
    background-color: var(--containers-bg-color);
    border-radius: 12px;
    box-shadow: 0 0 3px rgb(128, 128, 128);
}

.bio{
    width:50%;
    display: flex;
    flex-direction: column;
}

.container h3{
    line-height: var(--p-line-height);
    width: 100%;
    font-size: 18px;
    font-family: var(--p-font-family);
    color: var(--font-color);
    font-weight: var(--font-weight);
}
.bio p{
  line-height: var(--p-line-height);
    font-family: var(--p-font-family);
    color: var(--font-color);
    font-weight: var(--p-font-weight);
    text-align: justify;
    font-size: 16px;
}

.bio p a {
  font-weight: bold;
  text-decoration: none !important;
  color: var(--hover-green) !important;
}

.skills{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 35%;
  height: fit-content;
    align-items: center;
    padding:100px 0 0 0;
}

.tech{
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 3px rgb(128, 128, 128);
    font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: var(--dark-green);
  padding:5px 10px;
  /*text-transform: uppercase;*/
    height: fit-content;
}

/*RESPONSIVNESS*/
@media screen and (max-width: 425px) {
    .skills{
        display: none;
    }

    .aboutme{
        width: 100%;
    }
  .container{
      width: 100%;
      padding: 7%;
      border-radius: 0;
      flex-direction: column;
      box-shadow: unset;
  }
    .bio{
        width: 100%;
    }

    .bio h3{
        font-size: 15px;
    }

    .bio p{
        font-size: 12px;
    }
}

@media screen and (min-width: 426px) {
    .skills{
        display: none;
    }

    .aboutme{
        width: 100%;
    }
    .container{
        width: 100%;
        padding: 7%;
        border-radius: 0;
        flex-direction: column;
        box-shadow: unset;
    }
    .bio{
        width: 100%;
    }

    .bio h3{
        font-size: 17px;
    }

    .bio p{
        font-size: 14px;
    }
}

@media screen and (min-width: 769px) {
    .aboutme{
        width: 100%;
    }
    .container{
        width: 80%;
        padding: 7%;
        border-radius: 12px;
        flex-direction: row;
        box-shadow: 0 0 3px rgb(128, 128, 128);
    }
    .bio{
        width: 95%;
    }

    .bio h3{
        font-size: 18px;
    }

    .bio p{
        font-size: 16px;
    }
}

@media screen and (min-width: 1025px) {
    .skills{
        display: flex;
    }

    .aboutme{
        width: 100%;
    }
    .container{
        width: 80%;
        padding: 7%;
        border-radius: 12px;
        flex-direction: row;
        box-shadow: 0 0 3px rgb(128, 128, 128);
    }
    .bio{
        width: 50%;
    }

    .bio h3{
        font-size: 18px;
    }

    .bio p{
        font-size: 16px;
    }
}