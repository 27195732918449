:root {
  --white: #EDF9F5;
  --light-green: #B0E5D6;
  --green: #096A56;
  --hover-green: #0c8d73;
  --dark-green: #22495F;

  --button-height: 3.4375rem;
  --button-width: fit-content;
  --button-padding: 3.5rem;
  --button-alignement: center;
  --button-font-size: 1rem;
  --button-font-weight: 600;
  --button-letter-spacing: 0.05rem;
  --button-line-height: 1.54rem;
  --button-border-radius: 1.71875rem;

  --font-family: Ubuntu;
  --font-weight: 600;
  --font-color: #374151;
  --heading-font-size: 3.25rem;
  --line-height: 4.25rem;

  --p-font-family: "Roboto", sans-serif;
  --p-font-size: 1rem;
  --p-line-height: 1.75rem;
  --p-font-weight: 400;

  --anchors-decoration: none;

  --containers-bg-color: #d9eaf2;
}

html, body , #root, #app{
  height: 100%;
  margin: 0;
}


.cta{
  display: flex;
  justify-content: space-evenly;
  text-decoration: var(--anchors-decoration);
  background-color: var(--green);
  color: var(--white);
  font-family: var(--font-family);
  font-weight: var(--button-font-weight);
  font-size: var(--button-font-size);
  border-radius: var(--button-border-radius);
  height: var(--button-height);
  width: var(--button-width);
  align-items: var(--button-alignement);
  padding: 0 var(--button-padding);
}

.cta:hover{
  background-color: var(--hover-green);
}

.icon{
  fill:var(--white);
  margin-left: 5px;
}

h2 {
  color: var(--dark-green);
  font-family: var(--font-family);
  font-size: var(--heading-font-size);
  font-weight: var(--font-weight);
  text-align: left;
  margin: 0 0 60px 100px;
}