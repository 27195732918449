@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
:root {
  --white: #EDF9F5;
  --light-green: #B0E5D6;
  --green: #096A56;
  --hover-green: #0c8d73;
  --dark-green: #22495F;

  --button-height: 3.4375rem;
  --button-width: fit-content;
  --button-padding: 3.5rem;
  --button-alignement: center;
  --button-font-size: 1rem;
  --button-font-weight: 600;
  --button-letter-spacing: 0.05rem;
  --button-line-height: 1.54rem;
  --button-border-radius: 1.71875rem;

  --font-family: Ubuntu;
  --font-weight: 600;
  --font-color: #374151;
  --heading-font-size: 3.25rem;
  --line-height: 4.25rem;

  --p-font-family: "Roboto", sans-serif;
  --p-font-size: 1rem;
  --p-line-height: 1.75rem;
  --p-font-weight: 400;

  --anchors-decoration: none;

  --containers-bg-color: #d9eaf2;
}

html, body , #root, #app{
  height: 100%;
  margin: 0;
}


.cta{
  display: flex;
  justify-content: space-evenly;
  -webkit-text-decoration: var(--anchors-decoration);
          text-decoration: var(--anchors-decoration);
  background-color: var(--green);
  color: var(--white);
  font-family: var(--font-family);
  font-weight: var(--button-font-weight);
  font-size: var(--button-font-size);
  border-radius: var(--button-border-radius);
  height: var(--button-height);
  width: var(--button-width);
  align-items: var(--button-alignement);
  padding: 0 var(--button-padding);
}

.cta:hover{
  background-color: var(--hover-green);
}

.icon{
  fill:var(--white);
  margin-left: 5px;
}

h2 {
  color: var(--dark-green);
  font-family: var(--font-family);
  font-size: var(--heading-font-size);
  font-weight: var(--font-weight);
  text-align: left;
  margin: 0 0 60px 100px;
}
.Resume_pdf__Lt_5S{
    width:100%;
    height: 100%;
}
.Navbar_navbar__6wCID {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  z-index: 1;
  position: fixed;
  padding: 0.4rem;
}

.Navbar_navbar__6wCID ul {
  margin: 0;
  padding: 0;
  height: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  grid-gap: 5px;
  gap: 5px;
}

.Navbar_navbar__6wCID ul li {
  list-style-type: none;
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content:center;
}

.Navbar_navbar__6wCID ul li .Navbar_link__5WTop,
.Navbar_navbar__6wCID ul li button,
.Navbar_navbar__6wCID ul li a {
  text-align: center;
  text-decoration: none;

  font-family: var(--font-family);
  font-size: var(--button-font-size);
  font-weight: var(--font-weight);
  line-height: 3.438rem;

  cursor: pointer;
  vertical-align: middle;
  display: flex;
  align-items:center;
  justify-content:center;
  padding: 0 20px;

  border-radius: var(--button-border-radius);
  height: var(--button-height);
}

.Navbar_navbar__6wCID ul li .Navbar_link__5WTop:hover,
.Navbar_navbar__6wCID ul li a:hover {
  color: var(--hover-green);
}

.Navbar_navbar__6wCID ul li .Navbar_active__3_Lf0 {
  color: var(--white);
  background-color: var(--green);
}

.Navbar_navbar__6wCID ul li:last-child {
  display: none;
}

/*RESPONSIVENESS*/
@media screen and (max-width: 601px) {
  .Navbar_navbar__6wCID ul{
    justify-content: right;
  }
  .Navbar_navbar__6wCID ul li:not(:last-child) {
    display: none;
  }
  .Navbar_navbar__6wCID ul li:last-child {
    display: inline-block;
  }

  .Navbar_responsive__3e2U5 {
    background-color: var(--hover-green) !important;
    position: fixed;
    width: 100%;
    z-index: 1;
    padding-top:20px;
  }

  .Navbar_responsive__3e2U5 li{
    list-style-type: none;
  }

  .Navbar_responsive__3e2U5 li .Navbar_link__5WTop,
  .Navbar_responsive__3e2U5 li button,
  .Navbar_responsive__3e2U5 li a{
    float: none;
    display: block;
    text-align: left;
    color: var(--white);
    text-decoration: none;
    font-family: var(--font-family);
    /*font-size: 20px;*/
    cursor: pointer;
    padding: 10px 10px 10px;
    width: 100%;
  }

  .Navbar_responsive__3e2U5 ul li .Navbar_link__5WTop:hover,
  .Navbar_responsive__3e2U5 ul li a:hover {
    color: #14dda8;
  }
  .Navbar_responsive__3e2U5 ul li button .Navbar_icon__3FEv5 {
    position: absolute;
    right: 0;
    top: 0;
    padding: 18px 25px;
  }

  .Navbar_responsive__3e2U5 ul li .Navbar_active__3_Lf0 {
    color: var(--white);
    background-color: var(--green);
  }
}


/*Mobile*/


/*Tablets*/
@media screen and (max-width: 1024px) {}


.Navbar_hamburger__3AOEU{
  border: none;
  background-color: inherit;
}
.Home_hero_section__1XESJ{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 520px;
}

.Home_content__2Nin9{
  height:350px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}


/*LEFT SIDE*/
.Home_left_side__8cN4_{
  display: flex;
  flex-direction: column;
}

h1 {
  color: var(--dark-green);
  font-family: var(--font-family);
  font-size: var(--heading-font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
}
/**/


/*RIGHT SIDE*/
.Home_content__2Nin9 .Home_right_side__2XQzs{
  display: flex;
  flex-direction: column;
  grid-gap:20px;
  gap:20px;
}

.Home_right_side__2XQzs img {
  height: 300px;
  width: 300px;
  border-radius: 10px;
  object-fit: cover;
}

.Home_fa_icon__mPatT{
  height: 30px !important;
  width: 30px !important;
  color: var(--green);
}

.Home_fa_icon__mPatT:hover {
  color: #14dda8;
}

.Home_centered_link_container__3PMyd{
  animation: Home_MoveUpDown__1ludY 1s linear infinite;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Home_primary_button__UsWCJ {
  width: 40px;
  height:40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background-color: var(--green);
  font-size: 25px;
  border-radius: 50%;
  font-family: 'Roboto', sans-serif;
  padding: 5px;
  cursor: pointer;
}

.Home_primary_button__UsWCJ:hover {
  background-color: var(--hover-green);
}

@keyframes Home_MoveUpDown__1ludY {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media screen and (max-width: 600px){
  .Home_hero_section__1XESJ{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 6rem 0 5rem 0;
  }

  .Home_content__2Nin9{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    flex-direction: column-reverse;
    grid-gap:1rem;
    gap:1rem;
  }

  .Home_right_side__2XQzs{
    width: 80%;
    flex-direction: unset !important;
    justify-content: center;
  }

  .Home_right_side__2XQzs img{
    width: 100%;
    height: 80%;
  }

  .Home_left_side__8cN4_{
    width: 80%;
  }

  h1{
    margin-top: 2rem;
    font-size: 1.4rem;
    line-height: 2.5rem;
  }
}

@media screen and (min-width: 601px){
  .Home_hero_section__1XESJ{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 8rem 0 6rem 0;
  }

  .Home_content__2Nin9{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    grid-gap:1rem;
    gap:1rem;
  }

  .Home_right_side__2XQzs{
    width: 35%;
  }

  .Home_right_side__2XQzs img{
    width: 100%;
    height: 100%;
  }

  h1{
    font-size: 2rem;
    line-height: 3rem;
  }
}

@media screen and (min-width: 768px){
  h1{
    font-size: 2.3rem;
    line-height: 4rem;
  }
}

@media screen and (min-width: 1075px) {
  .Home_hero_section__1XESJ{
    display: flex;
    padding: unset !important;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 520px;
  }

  .Home_content__2Nin9{
    height:350px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .Home_left_side__8cN4_{
    display: flex;
    flex-direction: column;
  }

  h1 {
    color: var(--dark-green);
    font-family: var(--font-family);
    font-size: var(--heading-font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
  }

  .Home_content__2Nin9 .Home_right_side__2XQzs{
    width: 20% !important;
    display: flex;
    flex-direction: column;
    grid-gap:20px;
    gap:20px;
  }

  .Home_right_side__2XQzs img {
    height: 300px;
    width: 300px;
    border-radius: 10px;
    object-fit: cover;
  }

  .Home_fa_icon__mPatT{
    height: 30px !important;
    width: 30px !important;
    color: var(--green);
  }

  .Home_fa_icon__mPatT:hover {
    color: #14dda8;
  }

  .Home_centered_link_container__3PMyd{
    animation: Home_MoveUpDown__1ludY 1s linear infinite;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Home_primary_button__UsWCJ {
    width: 40px;
    height:40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--white);
    background-color: var(--green);
    font-size: 25px;
    border-radius: 50%;
    font-family: 'Roboto', sans-serif;
    padding: 5px;
    cursor: pointer;
  }

  .Home_primary_button__UsWCJ:hover {
    background-color: var(--hover-green);
  }
}

.Projects_projects__1XrCy {
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: center;
  width: 100%;
  margin: 150px 0;
}

.Projects_cards__X8OMs{
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 100px;
  gap: 100px;
}

/*RESPONSIVNESS*/
@media screen and (max-width: 500px) {
  .Projects_projects__1XrCy h2{
    font-size: 30px;
    margin: 0 0 40px 5%;
  }

  .Projects_cards__X8OMs{
    width: 100%;
  }
}

@media screen and (min-width: 501px) {
  .Projects_projects__1XrCy h2{
    font-size: 35px;
    margin: 0 0 50px 20px;
  }

  .Projects_cards__X8OMs{
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .Projects_projects__1XrCy h2{
    font-size: 3.25rem;
    margin: 0 0 80px 120px;
  }

  .Projects_cards__X8OMs{
    width: 100%;
  }
}


.ProjectCard_card__2IfZu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  height: 250px;
  width: 600px;
  border-radius: 15px;
  position: relative;
  float: right;
  background-color: var(--green);
  box-shadow: 0 0 10px var(--dark-green);
}

.ProjectCard_text__1U1C5{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ProjectCard_previewImage__3GFqp{
  width: 50%;
  object-fit: contain;
}


.ProjectCard_card__2IfZu .ProjectCard_projName__KIuO0 {
  font-size: 25px;
  font-family: 'Bebas Neue', cursive;
  color: white;
  margin: 0;
  letter-spacing: 0.3mm;
}

.ProjectCard_technologies__1_6KJ {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
  grid-gap: 5px;
  gap: 5px;
}
.ProjectCard_technologies__1_6KJ span{
  color: #14dda8;
  background-color: #1b262c;
  padding: 7px;
  font-size: 10px;
  font-weight: bolder;
  font-family: 'Roboto', sans-serif;
  border-radius: 7px;
}

.ProjectCard_description__3p7uP {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  margin: 5px 0 0;
}
.ProjectCard_buttons__1t2Xq {
  display: flex;
  flex-direction: row;
  justify-content: left;
  grid-gap: 5px;
  gap: 5px;
}

.ProjectCard_icon__2e-2X {
  color: white;
  font-size: 25px;
}

.ProjectCard_card_link__DrRNC{
  text-decoration: none;
  color: var(--white);
  /*border:2px solid ;*/
  cursor: pointer;
  padding:5px 8px;
  border-radius: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 10px;
  background-color: var(--hover-green);
}


@media screen and (max-width:500px){
  .ProjectCard_card__2IfZu{
    width:80% !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
  }

  .ProjectCard_container__2wFhR{
    display: flex;
    flex-direction: space-between;
    grid-gap:20px;
    gap:20px;
    width:100%;
  }

  .ProjectCard_projName__KIuO0{
    align-self: center;
  }

  .ProjectCard_previewImage__3GFqp{
    width:300px;
    align-self: center;
  }

  .ProjectCard_text__1U1C5{
    width: 100%;
    grid-gap:20px;
    gap:20px;
  }

  .ProjectCard_technologies__1_6KJ{
    width: 100%;
  }

  .ProjectCard_technologies__1_6KJ span{
    font-size: 10px;
  }

  .ProjectCard_description__3p7uP{
    font-size: 12px;
    margin:0;
    width:100%;
  }
}

@media screen and (min-width:501px){
  .ProjectCard_card__2IfZu{
    padding: 15px;
    height: 250px;
    width: 80%;
    flex-direction: column;
    justify-content: space-between;
  }

  .ProjectCard_container__2wFhR{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width:100%;
    height: 100%;
  }

  .ProjectCard_text__1U1C5{
    width: 60%;
    justify-content: space-evenly;
    grid-gap:20px;
    gap:20px;
  }

  .ProjectCard_technologies__1_6KJ{
    width: 100%;
  }

  .ProjectCard_technologies__1_6KJ span{
    font-size: 10px;
  }

  .ProjectCard_description__3p7uP{
    font-size: 12px;
    margin:0;
    width:100%;
  }
}

@media screen and (min-width:700px){
  .ProjectCard_card__2IfZu{
    padding: 25px 15px 15px 15px;
    height: 250px;
    width: 600px;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .ProjectCard_container__2wFhR{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width:100%;
    height: 100%;
  }



  .ProjectCard_previewImage__3GFqp{
    width:40%;
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
    border-radius: 15px;
  }

  .ProjectCard_text__1U1C5{
    width: 60%;
    justify-content: space-evenly;
  }

  .ProjectCard_technologies__1_6KJ{
    width: 100%;
  }

  .ProjectCard_technologies__1_6KJ span{
    font-size: 10px;
  }

  .ProjectCard_description__3p7uP{
    font-size: 15px;
    margin:0;
    width:100%;
  }
}
.InfoCard_page__2yZXg {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: white;
}

.InfoCard_navigation__Ym_Nt {
  position: fixed;
  width: 100%;
  background-color: var(--hover-green);
  height:50px;
  align-items: center;
  margin:0;
  padding:0 10px;
  z-index: 1;
}

.InfoCard_navigation__Ym_Nt li{
  margin:15px 15px 0;
  list-style-type: none;
  float: right;
}

.InfoCard_navigation__Ym_Nt li:first-child{
  margin:15px 0;
  float: left;
}

.InfoCard_navigation__Ym_Nt li a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap:5px;
  gap:5px;
  align-items: center;
  color: var(--white);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 17px;
  margin: 0;
  text-decoration: none;
  font-family: var(--font-family);
  cursor: pointer;
}

.InfoCard_navigation__Ym_Nt li a .InfoCard_icon__F6kyn {
  font-size: 15px;
}

.InfoCard_navigation__Ym_Nt a:hover {
  color: var(--containers-bg-color);
}

.InfoCard_caseStudy__1daRW{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.InfoCard_container__b8PqG{
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
}

.InfoCard_container__b8PqG img:nth-child(2){
  width:100px;
  height: 100px;
  object-fit: contain;
  position: relative;
  top:-50px;
  left:25%;
}

.InfoCard_sectionsNavigation__NnqvU{
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 30px;
  /*position: fixed;*/
}

.InfoCard_subcontainer__3K-Fx{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.InfoCard_subcontainer__3K-Fx h1{
  margin:0;
  padding: 0;
  font-family: var(--font-family);
  color: var(--green);
  font-size: 40px;
}

.InfoCard_subcontainer__3K-Fx p{
  font-family: var(--font-family);
  line-height: var(--p-line-height);
  width:100%;
  text-align: justify;
}

.InfoCard_subcontainer__3K-Fx h2{
  margin:50px 0 40px;
  padding: 0;
  font-size: 20px;
  color: var(--green)
}

.InfoCard_sectionsNavigation__NnqvU a{
  text-decoration: none;
  color:var(--dark-green);
  font-size: 14px;
  line-height: 2rem;
  font-family: var(--font-family);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.InfoCard_sectionsNavigation__NnqvU a:hover{
  color: var(--hover-green);
}

.InfoCard_copy__1DRQM{
  width: 80%;
  position: relative;
  padding: 0 7.5% 100px;
}

.InfoCard_copy__1DRQM img{
  display: block;
  width: 70%;
  height: 250px;
  object-fit: cover;
  margin: 0 auto;
  box-shadow: 0 0 4px rgb(128, 128, 128);
  border-radius: 5px;
}

.InfoCard_copy__1DRQM iframe{
  display: block;
  margin: 0 auto;
  width: 70%;
  height: 250px;
  border-radius: 5px;
  box-shadow: 0 0 4px rgb(128, 128, 128);
}

.InfoCard_footer__3ix_-{
  width: 100%;
  height: 40px;
  font-size: 10px;
  background-color: var(--containers-bg-color);
  text-align: center;
  font-family: var(--font-family);
  color:var(--dark-green);
  display: flex;
  align-items: center;
  justify-content: center;
}

.InfoCard_footer__3ix_- a{
  font-family: var(--font-family);
  color: var(--green);
  font-weight: var(--font-weight);
}

.InfoCard_footer__3ix_- a:hover{
  color:var(--hover-green)
}

@media screen and (max-width: 768px){
  .InfoCard_subcontainer__3K-Fx{
    flex-direction: column;
  }

  .InfoCard_sectionsNavigation__NnqvU{
    display: none;
  }

  .InfoCard_copy__1DRQM{
    width:100%;
    left: 0 !important;
  }

  .InfoCard_container__b8PqG img:nth-child(2){
    left:30px;
  }

  .InfoCard_copy__1DRQM h1{
    font-size: 25px;
  }

  .InfoCard_copy__1DRQM p{
    margin:0;
    width: 85%;
    font-size: 12px;
  }

  .InfoCard_copy__1DRQM img, .InfoCard_copy__1DRQM iframe{
    margin:0;
    width: 85%;
  }

  .InfoCard_footer__3ix_-{
    text-align: center;
    padding:0;
    width: 100%;
  }

  .InfoCard_footer__3ix_- a{
    align-self: center;
  }
}


.InfoCard_playBtn__2EkQM{
  margin: 150px 0 0 0;
  padding: 10px 20px;
  border-radius: 10px;
  font-family: 'roboto';
  background-color: palevioletred;
  color: white;
  font-weight: bold;
  font-size: medium;
}

.InfoCard_playBtn__2EkQM:hover{
  cursor: pointer;
}

.InfoCard_backgroundPicture__1crgb{
  width: 100%;
  height: 200px;
  object-fit: cover;
  background-color: black;
}
.Aboutme_aboutme__39u8p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 150px;
}
.Aboutme_container__-TqK3{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    grid-gap:50px;
    gap:50px;
    padding:50px;
    /*align-items: center;*/
    background-color: var(--containers-bg-color);
    border-radius: 12px;
    box-shadow: 0 0 3px rgb(128, 128, 128);
}

.Aboutme_bio__1ZUsM{
    width:50%;
    display: flex;
    flex-direction: column;
}

.Aboutme_container__-TqK3 h3{
    line-height: var(--p-line-height);
    width: 100%;
    font-size: 18px;
    font-family: var(--p-font-family);
    color: var(--font-color);
    font-weight: var(--font-weight);
}
.Aboutme_bio__1ZUsM p{
  line-height: var(--p-line-height);
    font-family: var(--p-font-family);
    color: var(--font-color);
    font-weight: var(--p-font-weight);
    text-align: justify;
    font-size: 16px;
}

.Aboutme_bio__1ZUsM p a {
  font-weight: bold;
  text-decoration: none !important;
  color: var(--hover-green) !important;
}

.Aboutme_skills__2twAh{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  width: 35%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
    align-items: center;
    padding:100px 0 0 0;
}

.Aboutme_tech__8Kj2d{
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 3px rgb(128, 128, 128);
    font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: var(--dark-green);
  padding:5px 10px;
  /*text-transform: uppercase;*/
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

/*RESPONSIVNESS*/
@media screen and (max-width: 425px) {
    .Aboutme_skills__2twAh{
        display: none;
    }

    .Aboutme_aboutme__39u8p{
        width: 100%;
    }
  .Aboutme_container__-TqK3{
      width: 100%;
      padding: 7%;
      border-radius: 0;
      flex-direction: column;
      box-shadow: unset;
  }
    .Aboutme_bio__1ZUsM{
        width: 100%;
    }

    .Aboutme_bio__1ZUsM h3{
        font-size: 15px;
    }

    .Aboutme_bio__1ZUsM p{
        font-size: 12px;
    }
}

@media screen and (min-width: 426px) {
    .Aboutme_skills__2twAh{
        display: none;
    }

    .Aboutme_aboutme__39u8p{
        width: 100%;
    }
    .Aboutme_container__-TqK3{
        width: 100%;
        padding: 7%;
        border-radius: 0;
        flex-direction: column;
        box-shadow: unset;
    }
    .Aboutme_bio__1ZUsM{
        width: 100%;
    }

    .Aboutme_bio__1ZUsM h3{
        font-size: 17px;
    }

    .Aboutme_bio__1ZUsM p{
        font-size: 14px;
    }
}

@media screen and (min-width: 769px) {
    .Aboutme_aboutme__39u8p{
        width: 100%;
    }
    .Aboutme_container__-TqK3{
        width: 80%;
        padding: 7%;
        border-radius: 12px;
        flex-direction: row;
        box-shadow: 0 0 3px rgb(128, 128, 128);
    }
    .Aboutme_bio__1ZUsM{
        width: 95%;
    }

    .Aboutme_bio__1ZUsM h3{
        font-size: 18px;
    }

    .Aboutme_bio__1ZUsM p{
        font-size: 16px;
    }
}

@media screen and (min-width: 1025px) {
    .Aboutme_skills__2twAh{
        display: flex;
    }

    .Aboutme_aboutme__39u8p{
        width: 100%;
    }
    .Aboutme_container__-TqK3{
        width: 80%;
        padding: 7%;
        border-radius: 12px;
        flex-direction: row;
        box-shadow: 0 0 3px rgb(128, 128, 128);
    }
    .Aboutme_bio__1ZUsM{
        width: 50%;
    }

    .Aboutme_bio__1ZUsM h3{
        font-size: 18px;
    }

    .Aboutme_bio__1ZUsM p{
        font-size: 16px;
    }
}
.Contact_contact__2fEhE {
  width: 100%;
  margin: 100px 0 220px 0;
}

.Contact_contact__2fEhE .Contact_subcontainer__2JPtM{
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 50px;
  gap: 50px;
}

.Contact_contact__2fEhE form {
  width:240px;
  height: inherit;
  background-color: var(--hover-green);
  box-shadow: 0 0 3.5px black;
  padding: 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap:10px;
  gap:10px;
}

.Contact_contact__2fEhE form .Contact_field__2AIAG{
  display: flex;
  flex-direction: column;
}

.Contact_contact__2fEhE form label {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  float: left;
  color: var(--white);
  font-weight: bold;
}

.Contact_contact__2fEhE form input,
#Contact_textarea__1eD1o {
  border-radius: 3px;
  width: 230px;
  color: var(--green);
  font-family: 'Roboto', sans-serif;
  font-size: small;
  background-color: var(--containers-bg-color);
  border-color: var(--white);
  box-shadow: 0 0 3px rgb(128, 128, 128);
}

.Contact_contact__2fEhE form input{
  height: 20px;
}


#Contact_textarea__1eD1o{
  resize: none;
  height: 80px;
}

.Contact_contact__2fEhE .Contact_submit__3W8Mq {
  background-color: var(--green);
  color: var(--white);
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: bold;
  padding: 5px;
  border-radius:3px;
  border: 1px solid var(--green);
  align-items: center;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 80px !important;
}

.Contact_contact__2fEhE .Contact_submit__3W8Mq:hover {
background-color: #0ea486;
  border: 1px solid #0ea486;

}

.Contact_testimonials__3oWLB{
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.Contact_testimonials__3oWLB h3{
  font-family: var(--font-family);
  color: var(--dark-green);
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin:0;
}

.Contact_testimony__3fCV8{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--containers-bg-color);
  box-shadow: 0 0 3px rgb(128, 128, 128);
  padding: 15px;
}

.Contact_referral__qDXwN{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.Contact_referral__qDXwN span{
  color: var(--dark-green);
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
}

.Contact_referral__qDXwN .Contact_icon__2k5U1{
  color:var(--dark-green);
  font-size: 10px;
}

.Contact_testimony__3fCV8 p{
  color: var(--dark-green);
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  margin: 5px 0 0 20px;
  font-weight: bold;
}

.Contact_social_link__2QP-T{
  color: var(--dark-green);
  font-size: 15px;
}

.Contact_right_side__3FDhR h4{
  color: var(--dark-green);
  font-size: 40px;
  font-family: 'Roboto', sans-serif;
  margin: 40px 0 10px 0;
}

.Contact_contact_info__2T2bV{
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  margin:0 0 10px 0;
}

.Contact_contact_info__2T2bV span{
  font-family: 'Roboto', sans-serif;
  color: var(--dark-green);
}

.Contact_form__2M2Bp .Contact_send__2vchk{
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap:10px;
  gap:10px;
}

.Contact_form__2M2Bp .Contact_send__2vchk .Contact_notification__vxQVL{
  font-size: 12px;
  color:var(--white);
  font-family: var(--font-family);
  float: left;
}

@keyframes Contact_spin__vIkNQ {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.Contact_spinner__3R7l4{
  color:var(--white);
  animation-name: Contact_spin__vIkNQ;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@media screen and (max-width: 425px) {
  .Contact_contact__2fEhE .Contact_subcontainer__2JPtM{
    flex-direction: column;
    align-items: center;
  }

  .Contact_form__2M2Bp{
    justify-content: center;
    width: 90% !important;
  }

  .Contact_contact__2fEhE form input,
  #Contact_textarea__1eD1o {
    width: 95%;
  }

  .Contact_right_side__3FDhR{
    width: 100%;
  }
}

@media screen and (min-width: 426px) {
  .Contact_contact__2fEhE .Contact_subcontainer__2JPtM{
    flex-direction: column;
    align-items: center;
  }

  .Contact_form__2M2Bp{
    justify-content: center;
    width: 60% !important;
  }

  .Contact_contact__2fEhE form input,
  #Contact_textarea__1eD1o {
    width: 95%;
  }

  .Contact_right_side__3FDhR{
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .Contact_contact__2fEhE .Contact_subcontainer__2JPtM{
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    grid-gap:30px;
    gap:30px;
  }

  .Contact_form__2M2Bp{
    justify-content: center;
    width: 240px !important;
  }

  .Contact_contact__2fEhE .Contact_subcontainer__2JPtM form input,
  #Contact_textarea__1eD1o {
    width: 95%;
  }

  .Contact_right_side__3FDhR{
    width: 60%;
  }
}
#Footer_footer__35UFM {
  position: absolute;
  background-color: var(--green);
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-gap:50px;
  gap:50px;
  justify-content: center;
  align-items: center;
}
.Footer_socials__24ZBq {
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap:20px;
  gap:20px;
}

.Footer_social_link__27e7W{
  height: 20px !important;
  width: 20px !important;
  color: white;
}

.Footer_social_link__27e7W:hover {
  color: var(--light-green);
}
