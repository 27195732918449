@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
.projects {
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  width: 100%;
  margin: 150px 0;
}

.cards{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
}

/*RESPONSIVNESS*/
@media screen and (max-width: 500px) {
  .projects h2{
    font-size: 30px;
    margin: 0 0 40px 5%;
  }

  .cards{
    width: 100%;
  }
}

@media screen and (min-width: 501px) {
  .projects h2{
    font-size: 35px;
    margin: 0 0 50px 20px;
  }

  .cards{
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .projects h2{
    font-size: 3.25rem;
    margin: 0 0 80px 120px;
  }

  .cards{
    width: 100%;
  }
}

